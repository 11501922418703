$theme-colors: (
  "primary": #00737f,
  "info": #007bff,
  "primary2": #fd7e14

);



@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';


.btn-primary2, .btn-primary2:disabled {
  color: #ffffff
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: theme-color("primary") !important;
}
.MuiAppBar-colorSecondary {
  background-color: theme-color("primary2") !important;
}

.MuiCircularProgress-colorPrimary {
  color: theme-color("primary") !important;

}

.form-group.required label:after {
  content:" *";
  color: theme-color("danger")

}

@include media-breakpoint-up(lg) {

  html, body, #root {
    overflow: hidden;
    height: 100%;
  }
    
}


.navbar-superior {
  position: absolute !important;
  left: 0;
  right: 0;
  height: 72px;
}

.conteudo-private {
  padding-top: 72px;
  padding-bottom: 25px;
}

.navbar-2 .navbar-brand {
  font-size: $font-size-sm;
} 
