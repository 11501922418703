@import '../../scss/custom.scss';

.containerCircles {
    position: absolute;
    left: 50%;
    bottom: 90px;
    cursor: pointer;
}

.outerCircle {
    left: -37px;
    height: 75px;
    width: 75px;
    background-color: hsla(0,0%,100%,.4);
    z-index: 1;
}

.innerCircle {
    left: 50%;
    top: 38px;
    height: 44px;
    width: 44px;
    background: #fff;
    margin: -22px 0 0 -22px;
    z-index: 2;
    padding: 11px;
}

.innerCircle, .outerCircle {
    position: absolute;
    border-radius: 50%;
}

.instruction {
    position: absolute;
    top: 0px;
    color: white;
    font-weight: bolder;
    text-align: center;
    width: 100%;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;

}

.camButton {
    color: theme-color("primary")
}
.container video {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}



.wrapperWebcam { 
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 1000;
    background-color: #000000a1;
}

.container {
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;

}
        
  
.imagemSelfie {
    width: 100%;
    height: 100%;
    min-height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./selfie.jpg");
}


.imagemPdf {
    width: 100%;
    height: 100%;
    min-height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./logo-pdf.png");
}