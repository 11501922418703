
.blur-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
 }


.login-form {
   max-width: 330px;
   padding: 25px;
   margin: auto;
   margin-bottom: 5vh;
   background-color: var(--light);
 }
 .login-form h6 {
   text-align: center;
   text-transform: uppercase;
   margin: 20px 0;
   font-weight: bolder;
 }
